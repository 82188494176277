<template>
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8337 10.166C13.9663 10.166 14.0934 10.2187 14.1872 10.3125C14.281 10.4062 14.3337 10.5334 14.3337 10.666V11.3327C14.3337 11.9957 14.0703 12.6316 13.6014 13.1005C13.1326 13.5693 12.4967 13.8327 11.8337 13.8327H5.16699C4.50395 13.8327 3.86807 13.5693 3.39923 13.1005C2.93038 12.6316 2.66699 11.9957 2.66699 11.3327V10.6687C2.66699 10.5361 2.71967 10.4089 2.81344 10.3151C2.90721 10.2214 3.03438 10.1687 3.16699 10.1687C3.2996 10.1687 3.42678 10.2214 3.52055 10.3151C3.61431 10.4089 3.66699 10.5361 3.66699 10.6687V11.3327C3.66699 11.7305 3.82503 12.112 4.10633 12.3933C4.38764 12.6746 4.76917 12.8327 5.16699 12.8327H11.8337C12.2315 12.8327 12.613 12.6746 12.8943 12.3933C13.1756 12.112 13.3337 11.7305 13.3337 11.3327V10.666C13.3337 10.5334 13.3863 10.4062 13.4801 10.3125C13.5739 10.2187 13.7011 10.166 13.8337 10.166Z"
      fill="#648FFF"
    />
    <path
      d="M9.00086 3C9.00086 2.86739 8.94818 2.74021 8.85442 2.64645C8.76065 2.55268 8.63347 2.5 8.50086 2.5C8.36825 2.5 8.24108 2.55268 8.14731 2.64645C8.05354 2.74021 8.00086 2.86739 8.00086 3V7.64667H5.8142C5.72056 7.74042 5.66797 7.8675 5.66797 8C5.66797 8.1325 5.72056 8.25958 5.8142 8.35333L8.14753 10.6867C8.24128 10.7803 8.36836 10.8329 8.50086 10.8329C8.63336 10.8329 8.76045 10.7803 8.8542 10.6867L11.1875 8.35333C11.2812 8.25958 11.3338 8.1325 11.3338 8C11.3338 7.8675 11.2812 7.74042 11.1875 7.64667H9.00086V3Z"
      fill="#648FFF"
    />
  </svg>
</template>
