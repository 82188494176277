<template>
  <footer class="bg-[#313233]">
    <div class="container">
      <div
        class="flex items-center justify-between py-12 md:gap-[20rem] sm:gap-[5rem] sm:flex-nowrap xs:flex-wrap"
      >
        <div class="w-full">
          <picture>
            <source
              media="(min-width: 768px)"
              srcset="/Images/logo_big.webp"
              type="image/webp"
            />
            <source
              media="(max-width: 767px)"
              srcset="/Images/logo_big.webp"
              type="image/webp"
            />
            <img
              src="/Images/logo.webp"
              alt="jernas-logo"
              loading="lazy"
              importance="high"
              role="logo"
              class="pointer-events-none"
              width="189"
            />
          </picture>
          <h4
            class="text-[#D9D9D9] xl:text-base sm:text-sm xs:text-sm tracking-wider font-thin my-6"
          >
            {{ t("footer_description") }}
          </h4>
        </div>
        <div class="flex items-center justify-between w-full gap-[5rem]">
          <div class="w-full">
            <h3 class="text-white font-semibold uppercase mb-4">Jernas</h3>
            <div v-for="link in jernaslinks" :key="link.path" class="mt-2">
              <RouterLink
                :to="`/${link.path}`"
                class="list-none text-[#A4A4A4] md:text-lg sm:text-base xs:text-xs font-normal capitalize cursor-pointer hover:text-[#FFFF] hover:border-b hover:border-[#FFFF]"
              >
                <span class="text-base font-[300]">{{ t(link.name) }}</span>
              </RouterLink>
            </div>
          </div>
          <div class="w-full">
            <h3 class="text-white font-semibold uppercase mb-4">
              {{ t("downloads") }}
            </h3>
            <div v-for="option in downloadLinks" :key="option" class="mt-2">
              <a
                class="list-none text-[#A4A4A4] md:text-lg sm:text-base text-nowrap xs:text-xs font-normal cursor-pointer hover:text-[#FFFF] hover:border-b hover:border-[#FFFF]"
                download
                target="_blank"
                :href="option.href"
              >
                <span class="text-base font-[300]">
                  {{ t(option.text) }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-black w-full py-5">
      <div class="container md:block xs:hidden">
        <div class="flex items-center justify-between">
          <div
            class="flex items-center w-full md:text-left xs:text-center gap-28"
          >
            <RouterLink
              to="/privacy-policy"
              class="text-[#7B7B7B] md:text-base sm:text-base xs:text-sm font-normal text-nowrap"
              >{{ t("privacy_policy") }}</RouterLink
            >
            <RouterLink
              to="/terms-of-use"
              class="text-[#7B7B7B] md:text-base sm:text-base xs:text-sm font-normal text-nowrap"
              >{{ t("terms_of_service") }}</RouterLink
            >
          </div>
          <div
            :class="[locale === 'ar' ? 'md:text-left' : 'md:text-right']"
            class="w-full xs:text-center"
          >
            <span
              class="text-[#7B7B7B] font-normal md:text-base sm:text-base xs:text-sm"
            >
              2024 © Jernas
            </span>
          </div>
        </div>
      </div>
      <div class="container md:hidden xs:block">
        <div class="flex items-center justify-between">
          <RouterLink
            to="/privacy-policy"
            class="text-[#7B7B7B] md:text-base sm:text-base xs:text-sm font-normal text-nowrap"
            >{{ t("privacy_policy") }}</RouterLink
          >
          <RouterLink
            to="/terms-of-use"
            class="text-[#7B7B7B] md:text-base sm:text-base xs:text-sm font-normal text-nowrap"
            >{{ t("terms_of_service") }}</RouterLink
          >
          <a
            class="text-[#7B7B7B] md:text-base sm:text-base xs:text-sm font-normal text-nowrap"
          >
            2024 © Jernas
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { inject, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();

const donwloadlinks = inject("Download_links");

const jernaslinks = ref([
  { name: "features", path: "feature" },
  { name: "download", path: "download" },
  { name: "security", path: "end-to-end-encryption" },
]);

const downloadLinks = ref([
  {
    id: 0,
    text: "Andriod",
    href: donwloadlinks.andriod,
  },
  {
    id: 0,
    text: "iOS",
    href: donwloadlinks.ios,
  },
  {
    id: 0,
    text: "mac_",
    href: donwloadlinks.macOS,
  },
]);
</script>
