<template>
  <div class="flex-1 w-full h-auto flex flex-col">
    <div
      class="mac-hero hidden md:flex w-full h-[42rem] justify-center items-center bg-blue-300"
    >
      <div class="container gap-3 flex justify-center items-center">
        <div class="flex-1 flex flex-col items-start">
          <h1
            class="text-[56px] leading-[60px] font-roboto font-bold text-black"
          >
            {{ $t("jernas_for_mac") }}
          </h1>
          <small class="text-brand-gray">
            {{ $t("jernas_for_mac_small") }}
          </small>
          <FilledButton
            class="mt-12 px-11 py-2 flex justify-center"
            fill-class="bg-black border-black text-white"
            @click="onMacClick"
          >
            <MacIcon class="w-9 fill-white" />
            <span class="leading-6">
              {{ $t("download_for_platform", { platform: $t("mac") }) }}
            </span>
          </FilledButton>
        </div>
        <div class="flex-1">
          <img src="/Images/mac-hero.png" alt="mac image for download" />
        </div>
      </div>
    </div>
    <div
      class="mobile-hero bg-brand-gray-600 md:bg-white p-5 md:p-24 flex-1 w-full h-full flex justify-center items-center"
    >
      <div
        class="container gap-3 flex flex-col md:flex-row justify-center items-center"
      >
        <div class="md:hidden flex flex-col gap-y-1">
          <span class="text-black-300 uppercase tracking-[0.5px]">
            {{ $t("download_for") }}
          </span>
          <h1
            class="text-[32px] leading-9 text-center capitalize font-semibold"
          >
            {{ $t("mobile") }}
          </h1>
        </div>
        <div class="flex-1 flex justify-center">
          <img
            :src="mobileImage"
            alt="mac image for download"
            class="w-72 h-auto"
          />
        </div>
        <div class="flex-1 w-full flex flex-col items-start">
          <h1
            class="hidden md:block text-[56px] leading-[60px] font-roboto font-bold text-black"
          >
            {{ $t("jernas_for_mobile") }}
          </h1>
          <small class="hidden md:block text-brand-gray">
            {{ $t("jernas_for_mobile_small") }}
          </small>
          <DownloadButtons
            alternate-look
            class="pt-8 w-full md:w-[unset]"
            button-class="ltr:w-60 rtl:w-64"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DownloadButtons from "@/components/Home/DownloadButtons.vue";
import FilledButton from "@/components/reuse/buttons/Filled.vue";
import MacIcon from "@/components/SvgIcons/MacIcon.vue";
import useDownload from "@/composables/useDownload";
import { inject, onMounted, onUnmounted, ref } from "vue";

const { macOS } = inject("Download_links");

const userAgent = inject("userAgent");
const isShow = ref(false);

const onMacClick = () => useDownload(macOS);

const mobileImage =
  userAgent == "apple"
    ? "/Images/mobile-ios-hero.png"
    : "/Images/mobile-android-hero.png";

const updateIsShow = () => {
  const innerWidth = window.innerWidth;
  if (
    (userAgent === "mac" && innerWidth <= 1024) ||
    (userAgent === "apple" && innerWidth <= 1024) ||
    (userAgent === "android" && innerWidth <= 1024) ||
    (userAgent === "window" && innerWidth <= 1024)
  ) {
    isShow.value = true;
  } else {
    isShow.value = false;
  }
};

onMounted(() => {
  updateIsShow();
  window.addEventListener("resize", updateIsShow);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateIsShow);
});
</script>

<style></style>
