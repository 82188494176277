<template>
  <svg
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4141 13.4998L13.7071 8.79285C13.6142 8.7 13.504 8.62636 13.3827 8.57611C13.2614 8.52586 13.1314 8.5 13.0001 8.5C12.8688 8.5 12.7387 8.52586 12.6174 8.57611C12.4961 8.62636 12.3859 8.7 12.2931 8.79285C12.2002 8.88569 12.1266 8.99592 12.0763 9.11722C12.0261 9.23853 12.0002 9.36855 12.0002 9.49985C12.0002 9.63115 12.0261 9.76117 12.0763 9.88247C12.1266 10.0038 12.2002 10.114 12.2931 10.2068L14.5861 12.4998H7.00006C6.73485 12.4998 6.48049 12.6052 6.29296 12.7927C6.10542 12.9803 6.00006 13.2346 6.00006 13.4998C6.00006 13.7651 6.10542 14.0194 6.29296 14.207C6.48049 14.3945 6.73485 14.4998 7.00006 14.4998H14.5861L12.2931 16.7928C12.1999 16.8855 12.1259 16.9957 12.0755 17.117C12.025 17.2383 11.999 17.3684 11.999 17.4998C11.999 17.6313 12.025 17.7614 12.0755 17.8827C12.1259 18.004 12.1999 18.1142 12.2931 18.2069C12.4806 18.3943 12.7349 18.4996 13.0001 18.4996C13.2652 18.4996 13.5195 18.3943 13.7071 18.2069L18.4141 13.4998Z"
      fill="#6084E0"
    />
  </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
