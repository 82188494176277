<template>
  <svg viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.222 4.05664H9.77753C9.30604 4.05664 8.85385 4.24394 8.52046 4.57734C8.18706 4.91074 7.99976 5.36292 7.99976 5.83442V27.1678C7.99976 27.6392 8.18706 28.0914 8.52046 28.4248C8.85385 28.7582 9.30604 28.9455 9.77753 28.9455H22.222C22.6935 28.9455 23.1457 28.7582 23.4791 28.4248C23.8125 28.0914 23.9998 27.6392 23.9998 27.1678V5.83442C23.9998 5.36292 23.8125 4.91074 23.4791 4.57734C23.1457 4.24394 22.6935 4.05664 22.222 4.05664ZM16.8886 27.1678H15.1109V25.39H16.8886V27.1678ZM9.77753 23.6122V5.83442H22.222V23.6122H9.77753Z"
      fill="#6084E0"
    />
  </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
