import "./assets/main.css";

import { createApp } from "vue";
import { createHead } from '@vueuse/head';
import App from "./App.vue";
import router from "./router/index";
import { i18n } from './i18';


import 'swiper/swiper-bundle.css';

const app = createApp(App);
const head = createHead();

const links = {
    email: import.meta.env.VITE_JERNAS_EMAIL,
    // apk
    andriod: import.meta.env.VITE_ANDROID_LINK,
    playStore: import.meta.env.VITE_GOOGLE_LINK,
    ios: import.meta.env.VITE_APPLE_LINK,
    macOS: import.meta.env.VITE_MAC_LINK,
    huawei: import.meta.env.VITE_HUAWEI_LINK
}

app.provide("Download_links" ,links)
app.use(router);
app.use(i18n);
app.use(head);
app.mount("#app");
