<template>
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1452_143039)">
      <path
        d="M23.3447 28.0454C21.9194 29.4271 20.3633 29.2089 18.8653 28.5545C17.28 27.8855 15.8257 27.8564 14.1531 28.5545C12.0589 29.4562 10.9535 29.1944 9.70279 28.0454C2.6055 20.73 3.65265 9.58958 11.7098 9.18236C13.6732 9.28417 15.0403 10.2586 16.1892 10.3459C17.9054 9.9968 19.5488 8.9933 21.3813 9.12419C23.5774 9.29871 25.2354 10.1713 26.3261 11.742C21.7885 14.4617 22.8648 20.4391 27.0242 22.1116C26.1953 24.2932 25.119 26.4602 23.3302 28.06L23.3447 28.0454ZM16.0438 9.0951C15.8257 5.85187 18.458 3.17585 21.4831 2.91406C21.9049 6.66632 18.0799 9.45869 16.0438 9.0951Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1452_143039">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
