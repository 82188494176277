<template>
  <div class="flex flex-col items-center gap-y-6 py-4">
    <template v-for="(button, key) in buttons">
      <FilledButton
        v-if="button.show"
        :key="key"
        :href="button.href"
        :class="buttonClass"
        :fill-class="[
          alternateLook
            ? 'bg-transparent border-brand-gray-300 active:opacity-80  !hover:opacity-100'
            : 'border-blue bg-blue text-white focus:text-blue focus:border-blue active:opacity-80  !hover:opacity-100',
        ]"
        as="a"
        class="min-w-11 w-full max-w-xs"
        target="_blank"
      >
        <div class="w-full flex gap-x-3 justify-center items-center">
          <div class="ltr:w-[27%] rtl:w-1/5 flex justify-end">
            <component :is="button.icon" class="size-9" />
          </div>
          <div class="w-3/5 flex gap-2 flex-col items-start justify-center">
            <span
              :class="[alternateLook ? 'text-brand-gray' : 'text-white/70']"
              class="text-xs font-light leading-3"
            >
              {{ $t("download_from") }}
            </span>
            <span
              :class="[alternateLook ? 'text-black' : 'text-white']"
              class="text-lg leading-3"
            >
              {{ $t(button.label) }}
            </span>
          </div>
        </div>
      </FilledButton>
    </template>
    <button
      v-if="userAgent == 'android' || !isMobile"
      class="flex gap-2.5 items-center text-blue"
      @click="useDownload(apk)"
    >
      <DownloadIcon class="w-7" />
      {{ $t("download_android_apk") }}
    </button>
  </div>
</template>

<script setup>
import useDownload from "@/composables/useDownload";
import { inject } from "vue";
import FilledButton from "../reuse/buttons/Filled.vue";
import AndroidColoredIcon from "../SvgIcons/AndroidColoredIcon.vue";
import AndroidIcon from "../SvgIcons/AndroidIcon.vue";
import AppleColoredIcon from "../SvgIcons/AppleColoredIcon.vue";
import AppleIcon from "../SvgIcons/AppleIcon.vue";
import DownloadIcon from "../SvgIcons/DownloadIcon.vue";
import HuaweiColoredIcon from "../SvgIcons/HuaweiColoredIcon.vue";
import HuaweiIcon from "../SvgIcons/HuaweiIcon.vue";

const props = defineProps({
  alternateLook: Boolean,
  buttonClass: String,
  buttonContentClass: {
    type: String,
    default: () => "",
  },
});

const isMobile = inject("isMobile");
const userAgent = inject("userAgent");
const { andriod: apk, playStore, ios, huawei } = inject("Download_links");

const buttons = [
  {
    show: userAgent == "apple" || !isMobile,
    href: ios,
    label: "ios_app_store",
    icon: props.alternateLook ? AppleColoredIcon : AppleIcon,
  },
  {
    show: userAgent == "android" || !isMobile,
    href: playStore,
    label: "android_app_store",
    icon: props.alternateLook ? AndroidColoredIcon : AndroidIcon,
  },
  {
    show: userAgent == "android" || !isMobile,
    href: huawei,
    label: "hua_app_store",
    icon: props.alternateLook ? HuaweiColoredIcon : HuaweiIcon,
  },
];
</script>

<style lang="scss" scoped></style>
