<template>
  <button
    v-bind="$attrs"
    class="cursor-pointer px-9 py-2 hover:opacity-80 border rounded-[10px] border-download-border"
    :class="[isHovered ? 'opacity-90' : '']"
    @click="handleClick"
  >
    <div class="flex items-center justify-center gap-2 w-full">
      <img
        :src="`/Icons/${icon}-hover.webp`"
        :alt="`download jernas for ${icon} device`"
        :role="`download for ${icon} device`"
        class="pointer-events-none text-[#6084E0]"
        :class="icon === 'apple' ? 'w-[28px] h-[28px]' : 'w-[32px] h-[32px]'"
      />
      <span
        class="px-2.5 text-sm font-normal cursor-pointer text-[#6084E0]"
        style="min-inline-size: max-content"
      >
        {{ $t("download_for") }} {{ label }}
      </span>
      <slot name="suffix"></slot>
    </div>
  </button>
</template>

<script setup>
import { ref } from "vue";

const isHovered = ref(false);
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "Download for",
  },
  href: {
    type: String,
  },
});

const handleClick = () => {
  if (!props.href) return;

  isHovered.value = true;

  const link = document.createElement("a");
  link.href = props.href;
  link.target = "_blank";
  link.download = "";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
</script>
